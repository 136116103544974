import { Button, Grid, makeStyles, TextField } from "@material-ui/core";
import { Fragment, React } from "react";
import contact from "../Img/contact.png";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    gridRowImage: {
        padding: 20,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    }
});

const Contact = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="contact">
                <h2>Contact</h2>
                <Grid container direction="row-reverse" >
                    <Grid item xs={12} md={4} lg={4} style={{ paddingTop: 50, textAlign: 'left' }}>
                        <div>
                            <h4 style={{ color: 'black' }}>Direct contact</h4>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Underwriting  <a href="mailto:underwriting@sternib.com">(underwriting@sternib.com)</a> (463) 231-2750</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Broker appointments <a href="mailto:bap@sternib.com">(bap@sternib.com)</a> (513) 883-6400</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Compliance <a href="mailto:compliance@sternib.com">(compliance@sternib.com)</a> (619) 568-5900</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Claims <a href="mailto:claims@sternib.com">(claims@sternib.com)</a> (949) 359-5600</p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>General inquiry <a href="mailto:generalinquiries@sternib.com">(generalinquiries@sternib.com)</a></p>
                            <p style={{ margin: '0px 0px 10px 0px' }}>Certificates <a href="mailto:certificates@sternib.com">(certificates@sternib.com)</a></p>
                        </div>

                    </Grid>
                    <Grid item xs={12} md={5} lg={5} style={{ padding: 60 }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', paddingTop: '30px' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Your Name
                            </Grid>
                            <Grid item xs={5} >
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Telephone
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                E-mail
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Message
                            </Grid>
                            <Grid item xs={5}>
                                <TextField multiline rows={4} fullWidth variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={7} style={{ padding: '25px', textAlign: 'end' }}>
                                <p style={{ fontWeight: 'bold', fontSize: '16px' }}>Contact  <a href="mailto:contact@sternib.com">(contact@sternib.com)</a><br /> (706) 395-4200</p>
                            </Grid>
                            <Grid item xs={5} style={{ paddingTop: '20px', textAlign: 'end' }}>
                                <Button variant="contained" color="primary">
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '10px' }}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} className={classes.gridRowImage}>

                        <img alt="customer service agent" src={contact} width="100%"></img>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default Contact;