import { Button, Card, CardContent, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Fragment, React } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    pos: {
        textAlign: 'left'
    }
});

const OfficeOfCompliance = () => {
    const classes = useStyles();
    return (
        <Fragment>
            <section id="officeofcompliance">
                <h2>Office of Compliance</h2>
                <Grid container>
                    {/* <Grid item xs="12" lg="6" style={{ padding: 20 }}>
                        <Card>
                            <CardMedia className={classes.media} />
                        </Card>
                    </Grid> */}
                    <Grid item xs={12} lg={6} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">
                                    <FadeInSection>
                                        <p >
                                            Our Office of Compliance strengthens accountability, promotes a culture of ethical conduct, and encourages a commitment to compliance with applicable laws, System policies and regulations, and  STERN rules and procedures.</p>
                                        <p>For any compliance suggestions or concerns, please contact the Office of Compliance and leave a message at the following phone number: (619) 568-5900
                                    </p>
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} md={5} lg={5} style={{ padding: 40 }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', padding: '15px', textAlign: 'left' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Your Name
                            </Grid>
                            <Grid item xs={5} >
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Telephone
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                E-mail
                            </Grid>
                            <Grid item xs={5}>
                                <TextField size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Message
                            </Grid>
                            <Grid item xs={5}>
                                <TextField multiline rows={4} fullWidth variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '25px', textAlign: 'end' }}>
                                <Button variant="contained" color="primary">
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" style={{ marginLeft: '15px' }}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </section>
        </Fragment>
    );
}

export default OfficeOfCompliance;