import { Button, Card, CardContent, FormControl, Grid, InputLabel, List, ListItem, ListItemText, makeStyles, MenuItem, Select, TextField, Typography } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import TransitionsModal from "./Helpers/TransitionModal";
import { Link } from "react-router-dom";
import config from "../config";
import st1 from "../Img/st1.jpg";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'white',
        boxShadow: 'none',
        height: '100%'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15,
        paddingTop: 20
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    hover: {
        "&$hover:hover": {
            color: 'black',
        },
    },
    itemText: {
        textAlign: 'center',
        color: '#11ABB0',
        fontSize: '20px'
    }
});
const BECOME_PRODUCER_FORM = gql`
  mutation addBecomeProducer($input: become_producer_input) {
    addBecomeProducer(input: $input) {
        id,
        corporationType,
        fullName,
        DBA,
        address,
        city,
        zip,
        state,
        phone,
        email,
        taxIdSS,
        licenseNumber,
        licenseState,
        licenseExpDate,
        statesWriteBusinessIn,
        businessVolumeLia,
        businessVolumePD,
        businessVolumeCargo,
        commitmentToUs,
        lossRatio,
        remarks,
        requestNumber
    }
  }
`;

const BecomeProducer = () => {
    //const initials ======================================================================================================
    const classes = useStyles();

    //local constants ======================================================================================================
    const [saveForm] = useMutation(BECOME_PRODUCER_FORM);
    const [inputKey, setInputKey] = useState('asd')
    const [file, setFile] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [bapForm, setBapForm] = useState({
        id: uuidv4(),
        corporationType: "",
        fullName: "",
        DBA: "",
        address: "",
        city: "",
        zip: "",
        state: "",
        phone: "",
        email: "",
        taxIdSS: "",
        licenseNumber: "",
        licenseState: "",
        licenseExpDate: "",
        statesWriteBusinessIn: "",
        businessVolumeLia: "",
        businessVolumePD: "",
        businessVolumeCargo: "",
        commitmentToUs: "",
        lossRatio: "",
        remarks: "",
    })
    //handles (react-dom) ======================================================================================================
    const handleChange = (e, field) => {
        setBapForm({
            ...bapForm,
            [e.target.name]: e.target.value
        })
    }
    const handleCorpTypeChange = (e) => {
        setBapForm({
            ...bapForm,
            [e.target.name]: e.target.value
        })
    }
    const handleReset = () => {
        setInputKey(Math.random().toString(36))
        setBapForm({
            id: uuidv4(),
            corporationType: "",
            fullName: "",
            DBA: "",
            address: "",
            city: "",
            zip: "",
            state: "",
            phone: "",
            email: "",
            taxIdSS: "",
            licenseNumber: "",
            licenseState: "",
            licenseExpDate: "",
            statesWriteBusinessIn: "",
            businessVolumeLia: "",
            businessVolumePD: "",
            businessVolumeCargo: "",
            commitmentToUs: "",
            lossRatio: "",
            remarks: "",
        })

    }

    const handleSave = async e => {
        saveForm({ variables: { input: bapForm } }).then((res) => {
            console.log(res);
            const formData = new FormData();
            Array.from(file).map(fileSend => (
                formData.append('file', fileSend)
            ))
            formData.append('formId', res.data.addBecomeProducer.id);
            formData.append('requestNumber', res.data.addBecomeProducer.requestNumber);
            formData.append('requestEmail', res.data.addBecomeProducer.email);
            try {
                axios.post(config.Server + 'upload/', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })


            } catch (error) {

            }
            handleReset();
            setModalOpen(true);
        });
    }

    const handleFilesChange = (e) => {
        //let filesList = Array.from(e.target.files).map((file => file))
        setFile(e.target.files);
    }


    return (
        <Fragment>
            <section id="becomeproducer">
                <h2>Getting appointed is as easy as 1, 2, 3</h2>
                <br />
                <Grid container spacing={1} style={{ color: 'black' }}>
                    {/* <Grid item xs={12} md={2} lg={2}>
                        <FormControl variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}>
                            <InputLabel id="demo-simple-select-outlined-label" >Corporation Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-outlined-label"
                                id="demo-simple-select-outlined"
                                value={bapForm.corporationType}
                                onChange={handleCorpTypeChange}
                                label="Corporation Type"
                                name="corporationType"
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                <MenuItem value={"Corporation"}>Corporation</MenuItem>
                                <MenuItem value={"LLC"}>LLC</MenuItem>
                                <MenuItem value={"Individual"}>Individual</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Full Name
                            </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="fullName"
                            value={bapForm.fullName}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        DBA
                            </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="DBA"
                            value={bapForm.DBA}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Address
                            </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="address"
                            value={bapForm.address}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        City
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="city"
                            value={bapForm.city}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Zip
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="zip"
                            value={bapForm.zip}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        State
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="state"
                            value={bapForm.state}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Phone
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="phone"
                            value={bapForm.phone}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Email
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="email"
                            value={bapForm.email}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Tax ID / SS
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="taxIdSS"
                            value={bapForm.taxIdSS}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        License #
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseNumber"
                            value={bapForm.licenseNumber}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        State
                            </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseState"
                            value={bapForm.licenseState}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        Exp Date
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="licenseExpDate"
                            value={bapForm.licenseExpDate}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={false} md={1} lg={1}>
                    </Grid>
                    <Grid item xs={12} md={3} lg={3} style={{ fontSize: 14 }}>
                        State(s) you want to write business in?
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="statesWriteBusinessIn"
                            value={bapForm.statesWriteBusinessIn}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        Volume of your business
                            </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="Lia" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumeLia"
                            value={bapForm.businessVolumeLia}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="PD" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumePD"
                            value={bapForm.businessVolumePD}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={1} lg={1}>
                        <TextField label="Cargo" size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="businessVolumeCargo"
                            value={bapForm.businessVolumeCargo}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Commitment to us
                            </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="commitmentToUs"
                            value={bapForm.commitmentToUs}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Loss ratio
                            </Grid>
                    <Grid item xs={12} md={3} lg={3}>
                        <TextField size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="lossRatio"
                            value={bapForm.lossRatio}
                            onChange={handleChange} />
                    </Grid>
                    <Grid item xs={false} md={3} lg={3}>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        Please upload all your licenses and E&O
                            </Grid>
                    <Grid item xs={12} md={5} lg={5}>
                        <input onChange={handleFilesChange} key={inputKey} type="file" multiple style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}></input>
                    </Grid>
                    <Grid item xs={false} md={3} lg={3}>
                    </Grid>
                    <Grid item xs={12} md={2} lg={2}>
                        Remarks
                            </Grid>
                    <Grid item xs={12} md={10} lg={10}>
                        <TextField multiline rows={4} size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}
                            name="remarks"
                            value={bapForm.remarks}
                            onChange={handleChange} />
                    </Grid>


                    <Grid item xs={12}>
                        <Button variant="contained" color="primary" onClick={handleReset}>
                            Reset
                                </Button>
                        <Button variant="contained" onClick={handleSave} style={{ marginLeft: '15px', backgroundColor: '#7af043' }}>
                            Submit
                                </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button className={classes.hover} style={{ float: 'right' }} color="primary">
                            <Link to="/files/Application for Appointment.pdf" target="_blank" download>Download Form</Link>
                        </Button>
                    </Grid>
                </Grid>
                <TransitionsModal isOpen={modalOpen} setModalOpen={setModalOpen}></TransitionsModal> */}
                    <Grid item xs={12} lg={6} style={{ padding: 10 }}>
                        <img alt="appointing brokers" src={st1} width="70%" height="auto" />
                    </Grid>
                    <Grid item xs={12} lg={6} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} color="textSecondary">

                                    <p style={{ fontSize: '17px', textAlign: 'left' }}>
                                        If you are interested in becoming a producer for STERN, please complete, sign and date each of the forms below. You then submit them along with your current licenses, E&O Declaration page and W-9 to <a href="mailto:bap@sternib.com">(bap@sternib.com)</a>. Submitting all of the requested documents will expedite the appointment process. If you have any questions please contact <a href="mailto:bap@sternib.com">(bap@sternib.com)</a> or call (513) 883-6400.
                                   <br />
                                        <br />
                                        You can download and print the forms
                                        </p>
                                    <List>
                                        <ListItem button className={classes.itemText} component="a" {...{ href: "/files/bapform2.pdf", target: "_blank", download: 'download' }}>
                                            <ListItemText>
                                                <h3 style={{ color: "#11ABB0" }}>
                                                    Application For Appointment
                                                </h3>

                                            </ListItemText>
                                        </ListItem>
                                        <ListItem button className={classes.itemText} component="a" {...{ href: "/files/bapform1.pdf", target: "_blank", download: 'download' }}>
                                            <ListItemText>
                                                <h3 style={{ color: "#11ABB0" }}>
                                                    Producing Broker Release Authorizing Contact With E&O Carrier
                                                </h3>

                                            </ListItemText>
                                        </ListItem>

                                        <ListItem button className={classes.itemText} component="a" {...{ href: "/files/bapform3.pdf", target: "_blank", download: 'download' }}>
                                            <ListItemText>
                                                <h3 style={{ color: "#11ABB0" }}>
                                                    Producer Confidentiality Agreement
                                                </h3>

                                            </ListItemText>
                                        </ListItem>
                                        <ListItem button className={classes.itemText} component="a" {...{ href: "/files/bapform4.pdf", target: "_blank", download: 'download' }}>
                                            <ListItemText>
                                                <h3 style={{ color: "#11ABB0" }}>
                                                    Broker Agreement
                                                </h3>

                                            </ListItemText>
                                        </ListItem>
                                    </List>

                                    <br />
                                    <br />
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </section>
        </Fragment >
    );
}

export default BecomeProducer;