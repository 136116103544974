import { Collapse, Fab, Grid, List, ListItem, makeStyles, useMediaQuery } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import logo from "../Img/roundSternLogo.png";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import DescriptionIcon from '@material-ui/icons/Description';
import AssignmentIcon from '@material-ui/icons/Assignment';
import PhoneIcon from '@material-ui/icons/Phone';
import CarouselComponent from "./Helpers/CarouselComponent";


const useStyles = makeStyles((theme) => ({
    nested: {
        padding: '10px 10px 10px 80px !important',
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    buttonQuote: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        color: 'white !important',
        width: '185px !important',
        marginRight: '15px',
    },
    buttonsBap: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        marginRight: '15px',
        color: 'white !important',
        width: '185px'
    },
    buttonContact: {
        backgroundColor: '#f78320',
        fontWeight: 'bold',
        color: 'white !important',
        width: '185px !important'
    }
}));
const NavBar = () => {
    const classes = useStyles();
    const width = useMediaQuery('(min-width:770px)');
    const [open, setOpen] = useState(false);
    const [currentSection, setCurrentSection] = useState('#coverages');

    const handleClick = () => {
        setOpen(!open);
    };

    const handleCloseCoverages = () => {
        setOpen(false)
    }

    const handleNextSectionClick = () => {
        let section = window.location.href;
        section = section.substring(section.indexOf('#'))
        console.log(section)
        switch (section) {
            case "":
            case "#home":
                setCurrentSection('#markets')
                break;
            case "#coverages":
                setCurrentSection('#about')
                break;
            case "#markets":
                setCurrentSection('#getquote')
                break;
            case "#about":
                setCurrentSection('#becomeproducer')
                break;
            case "#getquote":
                setCurrentSection('#contact')
                break;
            case "#becomeproducer":
                setCurrentSection('#claims')
                break;
            case "#contact":
                setCurrentSection('#officeofcompliance')
                break;
            case "#claims":
                setCurrentSection('#careers')
                break;
            case "#officeofcompliance":
                setCurrentSection('#resources')
                break;
            case "#careers":
                setCurrentSection('#home')
                break;
            case "#resources":
                setCurrentSection('#coverages')
                break;
            default:
                break;
        }

    };

    return (
        <Fragment>
            <header id="home">
                <nav id="nav-wrap">
                    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                    <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                    <ul id="nav" className="nav">
                        <li className="current"><a onMouseEnter={handleCloseCoverages} className="smoothscroll" href="#home">HOME</a></li>
                        <li>
                            <a className="smoothscroll" href="#coverages">PRODUCT LINES </a>

                        </li>
                        <li><a className="smoothscroll" href="#markets" onMouseEnter={handleCloseCoverages}>MARKETS</a></li>
                        <li><a className="smoothscroll" href="#about">ABOUT US</a></li>
                        <li><a className="smoothscroll" href="#getquote">GET QUOTE</a></li>
                        <li><a className="smoothscroll" href="#becomeproducer">BECOME PRODUCER</a></li>
                        <li><a className="smoothscroll" href="#contact">CONTACT US</a></li>
                        <li><a className="smoothscroll" href="#claims">CLAIMS</a></li>
                        <li><a className="smoothscroll" href="#officeofcompliance">COMPLIANCE</a></li>
                        <li><a className="smoothscroll" href="#careers">CAREERS</a></li>
                        {/* <li><a className="smoothscroll" href="#preferedvendors">Vendors</a></li>*/}
                        <li><a className="smoothscroll" href="#resources">RESOURCES</a></li>
                    </ul>
                    <Collapse in={open} timeout="auto" unmountOnExit style={{ backgroundColor: '#132d5a', color: 'white' }}>
                        <List component="div" disablePadding onMouseLeave={handleClick}>
                            <ListItem className={classes.nested}>
                                Commercial Auto liability
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Collision coverage / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Uninsured and underinsured motorist coverage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Comprehensive / Physical Damage
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Motor Truck Cargo (MTC)
                            </ListItem>
                            <ListItem className={classes.nested}>
                                Trailer Interchange
                            </ListItem>
                        </List>
                    </Collapse>
                </nav>

                {
                    !width ?
                        // ------- MOBILE
                        <Grid container style={{ float: 'left' }}>

                            <Grid item xs={12} lg={6} md={6} >
                                <div className="banner-text">
                                    <img alt="company logo" src={logo} width="40%"></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} lg={6} md={6}>
                                <div style={{ textAlign: 'center' }}>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonsBap}
                                        href="#becomeproducer"
                                    >
                                        <AssignmentIcon className={classes.extendedIcon} />
                                    Get Appointed
                                    </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonQuote}
                                        href="#getquote"

                                    >
                                        <DescriptionIcon className={classes.extendedIcon} />
                                            Get a Quote
                                        </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonContact}
                                        href="#contact"

                                    >
                                        <PhoneIcon className={classes.extendedIcon} />
                                            Contact us
                                        </Fab>
                                    <h2>
                                        Please call us (706) 395-4200
                                        </h2>
                                </div>

                                {/* <p style={{ marginTop: '10%', textAlign: "justify", lineHeight: '20px', padding: '20px' }}>
                                    STERN is an acting service and processing agent for various MGA’s, Carriers and RRG’s. Through  our network of MGA’s, Carriers and RRG’s STERN appoints and provides producers with  the markets they need to write their business. <br />
                                We at  STERN understand the commercial insurance needs of producers, independent agents and brokers and our goal is to help you succeed by providing you with  exceptional services and underwriting expertise to place your business.
                                </p> */}
                            </Grid><Grid item xs={12} style={{ height: '60vh' }}>
                                <CarouselComponent />
                            </Grid>y
                        </Grid>
                        :

                        <Grid container style={{ float: 'left', marginTop: '52px' }}>
                            <Grid item xs={false} sm={9} lg={9} md={9} style={{ paddingTop: '70px' }}>
                                <div style={{ textAlign: 'center' }}>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonsBap}
                                        href="#becomeproducer"
                                    >
                                        <AssignmentIcon className={classes.extendedIcon} />
                                    Get Appointed
                                    </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonQuote}
                                        href="#getquote"

                                    >
                                        <DescriptionIcon className={classes.extendedIcon} />
                                            Get a Quote
                                        </Fab>
                                    <Fab
                                        variant="extended"
                                        size="medium"
                                        color="primary"
                                        aria-label="add"
                                        className={classes.buttonContact}
                                        href="#contact"

                                    >
                                        <PhoneIcon className={classes.extendedIcon} />
                                            Contact us
                                        </Fab>
                                    <h2 style={{ float: 'right', textShadow: '1px 1px #EB8224' }}>
                                        Please call us (706) 395-4200
                                        </h2>
                                </div>
                            </Grid>

                            <Grid item xs={12} sm={3} lg={3} md={3}>
                                <div className="banner-text" >
                                    <img style={{ position: 'relative', zIndex: '9000' }} alt="company logo" src={logo} width="70%"></img>
                                </div>
                            </Grid>
                            <Grid item xs={12} style={{ height: '60vh', position: 'absolute', bottom: '0px', backgroundColor: 'black' }}>
                                <CarouselComponent />
                                {/* <div style={{ backgroundColor: 'transparent' }}>
                                    <p style={{ color: 'black', marginTop: '10px', textAlign: "justify", lineHeight: '20px', paddingLeft: '40px' }}>
                                        STERN is an acting service and processing agent for various MGA’s, Carriers and RRG’s. Through  our network of MGA’s, Carriers and RRG’s STERN appoints and provides producers with  the markets they need to write their business.
                                        We at  STERN understand the commercial insurance needs of producers, independent agents and brokers and our goal is to help you succeed by providing you with  exceptional services and underwriting expertise to place your business.
                                        We offer customized coverage and solutions to truckers, businesses and producers when standard markets don’t meet the needs of the insured.
                                </p>
                                </div> */}


                            </Grid>
                        </Grid>
                }


                {/* <p className="scrolldown">
                    <a className="smoothscroll" style={{ position: 'fixed', left: 'auto', right: '80px' }} href={currentSection} onClick={handleNextSectionClick}><i className="icon-down-circle" style={{ color: 'black' }}></i></a>
                </p> */}

            </header>
        </Fragment>
    );
}

export default NavBar;