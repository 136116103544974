import { Button, Card, CardContent, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import { Fragment, React, useState } from "react";
import { FadeInSection } from "./Helpers/FadeinSection";
import { gql, useMutation } from '@apollo/client';
import axios from 'axios';
import TransitionModalClaim from "./Helpers/TransitionModalClaim";

const useStyles = makeStyles({
    root: {
        minWidth: 275,
        color: '#838C95',
        padding: 10,
        backgroundColor: 'transparent',
        boxShadow: 'none'
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    pos: {
        marginBottom: 12,
        fontSize: 15,
        textAlign: 'left'
    },
    coveragesBackground: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '50% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    coveragesBackgroundMobil: {
        // backgroundImage: `url(${background}) !important`,
        // backgroundSize: '100% 100%',
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right',
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
});

const CLAIM_FORM = gql`
  mutation addClaim($input: claim_input) {
    addClaim(input: $input) {
        id,
        accidentLocation,
        fullName,
        phoneNumber,
        email,
        date,
        policyNumber,
        accidentDescription
    }
  }
`;

const Claims = () => {
    const classes = useStyles();
    const [saveClaim] = useMutation(CLAIM_FORM);
    const [file, setFile] = useState('');
    const [idClaim, setIdClaim] = useState('');
    const [modalOpen, setModalOpen] = useState(false);
    const [claim, setClaim] = useState({
        accidentLocation: "",
        fullName: "",
        phoneNumber: "",
        email: "",
        date: "",
        policyNumber: "",
        accidentDescription: ""
    })

    const handleFilesChange = (e) => {
        //let filesList = Array.from(e.target.files).map((file => file))
        setFile(e.target.files);
    }

    const handleChange = (e) => {
        console.log(e.target.name)
        setClaim({
            ...claim,
            [e.target.name]: e.target.value
        })
    }

    const handleReset = () => {
        setClaim({
            accidentLocation: "",
            fullName: "",
            phoneNumber: "",
            email: "",
            date: "",
            policyNumber: "",
            accidentDescription: ""
        })
    }

    const handleSave = async e => {
        saveClaim({ variables: { input: claim } }).then((res) => {
            const formData = new FormData();
            Array.from(file).map(fileSend => (
                formData.append('file', fileSend)
            ))
            formData.append('formId', res.data.addClaim.id);
            formData.append('requestEmail', res.data.addClaim.email);
            formData.append('requestNumber', res.data.addClaim.id);
            try {
                axios.post('http://server.sternib.com/uploadPhotos/', formData, {
                    //const res = axios.post('http://localhost:8081/uploadPhotos', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })


            } catch (error) {

            }
            handleReset();
            setIdClaim(res.data.addClaim.id)
            setModalOpen(true);
        });
    }

    return (
        <Fragment>
            <section id="claims">
                <h2>Claims</h2>
                <Grid container>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Grid container justify="center" style={{ backgroundColor: '#f1f1f169', paddingTop: '30px' }}>
                            <Grid item xs={2} style={{ marginBottom: '10px' }}>
                            </Grid>
                            <Grid item xs={3}>
                                Accident Location
                            </Grid>
                            <Grid item xs={5} >
                                <TextField name="accidentLocation" value={claim.accidentLocation} onChange={handleChange} size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Full Name
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="fullName" value={claim.fullName} onChange={handleChange} size="small" variant="outlined" fullWidth style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Phone Number
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="phoneNumber" value={claim.phoneNumber} onChange={handleChange} size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Email
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="email" value={claim.email} onChange={handleChange} size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Date
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="date" value={claim.date} onChange={handleChange} size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Policy Number
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="policyNumber" value={claim.policyNumber} onChange={handleChange} size="small" fullWidth variant="outlined" style={{ marginBottom: '10px', backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>

                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={3}>
                                Accident Description
                            </Grid>
                            <Grid item xs={5}>
                                <TextField name="accidentDescription" value={claim.accidentDescription} onChange={handleChange} multiline rows={4} fullWidth variant="outlined" style={{ backgroundColor: 'white' }} />
                            </Grid>
                            <Grid item xs={2}>
                            </Grid>
                            <Grid item xs={12} md={5} lg={5}>
                                <br />
                                <input onChange={handleFilesChange} key={"asd"} type="file" multiple style={{ marginBottom: '10px', font: '', backgroundColor: 'white' }}></input>
                            </Grid>
                            <Grid item xs={12} style={{ padding: '25px', textAlign: 'end' }}>
                                <Button variant="contained" color="primary" onClick={handleReset}>
                                    Reset
                                </Button>
                                <Button variant="contained" color="primary" onClick={handleSave} style={{ marginLeft: '15px' }}>
                                    Send
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} style={{ padding: 20 }}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography className={classes.pos} style={{ marginTop: "30%" }} color="textSecondary">
                                    <FadeInSection>
                                        {/* <img src={claims} width="100%"></img> */}
                                        <p>
                                            STERN is ready to help manage your claim as quickly and efficiently as possible. Report a claim and start the claims process.
                                        </p>
                                        <p>
                                            Report a Claim at: <a href="mailto:claims@sternib.com">claims@sternib.com</a>
                                        </p>
                                        <br /><br /><br /><br />
                                    </FadeInSection>
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <TransitionModalClaim isOpen={modalOpen} setModalOpen={setModalOpen} reqNumber={idClaim}></TransitionModalClaim>
            </section>
        </Fragment>
    );
}

export default Claims;